import * as React from 'react';

const Reset = (props) => (
  <svg
    height={21}
    viewBox="0 0 21 21"
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(2 2)"
    >
      <path d="m12.5 1.5c2.4138473 1.37729434 4 4.02194088 4 7 0 4.418278-3.581722 8-8 8s-8-3.581722-8-8 3.581722-8 8-8" />
      <path d="m12.5 5.5v-4h4" />
    </g>
  </svg>
);
export default Reset;
